<template>
  <ul class="flex flex-col space-y-2">
    <template>
      <li class="text-black text-xl font-semibold p-2 mt-2">
        Personalentwicklungsmassnahmen festlegen
      </li>
      <li class="flex flex-col w-full rounded border card">
        <a
          href=""
          @click.prevent="
            $emit('current', current === `${$options.name}` ? null : `${$options.name}`)
          "
          class="bg-gray-100 pl-3 pr-3 pt-3 pb-3 text-sm font-semibold card-header"
          >Personalentwicklungsmassnahmen definieren</a
        >
        <div v-if="current === $options.name" class="flex flex-col w-full p-2">
          <div class="flex self-end space-x-2">
            <button
              :class="isAssesment ? 'rounded p-1 bg-blue-600' : 'rounded p-1 bg-green-400'"
              @click.prevent="addAction"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          <ul class="flex flex-col space-y-2 my-1 list-none">
            <li class="flex space-x-2 font-semibold m-0">
              <div class="w-1/6">Massnahme</div>
              <div class="w-1/3">Beschreibung</div>
              <div class="w-1/4">Typ</div>
              <div class="w-1/4">Realisation</div>
              <div class="w-1/4">Kosten (Mitarbeiter)</div>
              <div class="w-1/4">Kosten (Firma)</div>
              <div class="w-1/12"></div>
            </li>
            <li
              class="flex space-x-2 font-semibold m-0"
              v-for="(row, index) in $v.data.$each.$iter"
              :key="`development-action-${index}`"
            >
              <template v-if="data[index].new">
                <div class="w-1/6">
                  <input
                    type="text"
                    v-model="row.title.$model"
                    class="border rounded px-2 py-1 w-full"
                  />
                </div>
                <div class="w-1/3">
                  <input
                    type="text"
                    v-model="row.description.$model"
                    class="border rounded px-2 py-1 w-full"
                  />
                </div>
                <div class="w-1/4">
                  <select v-model="row.type.$model" class="border rounded px-2 py-1 w-full">
                    <option value="">Typ auswählen</option>
                    <option value="intern">Intern</option>
                    <option value="extern">Extern</option>
                  </select>
                </div>
                <div class="w-1/4">
                  <date-picker
                    v-model="row.realization.$model"
                    input-class="border rounded p-1 text-xs appearance-none focus:outline-none w-full"
                  />
                </div>
                <div class="w-1/4">
                  <input
                    type="text"
                    v-model="row.cost_employe.$model"
                    class="border rounded px-2 py-1 w-full"
                  />
                </div>
                <div class="w-1/4">
                  <input
                    type="text"
                    v-model="row.cost_company.$model"
                    class="border rounded px-2 py-1 w-full"
                  />
                </div>
                <div class="w-1/12">
                  <button
                    class="rounded px-2 py-1 bg-red-600 text-white"
                    @click.prevent="removeElement(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import _ from "lodash";

const tempDevelopmentAction = {
  title: '',
  description: '',
  type: '',
  realization: '',
  cost_employe: 0,
  cost_company: 0,
  new: true,
}

export default {
  name: 'review-define-development-actions',
  props: ['rows', 'current', 'isAssesment', 'evaluateActions',],
  data() {
    return {
      data: [],
      showTip: false,
    }
  },
  validations: {
    data: {
      $each: {
        title: {
          required,
        },
        description: {
          required,
        },
        type: {
          required,
        },
        realization: {
          required,
        },
        cost_employe: {
          required,
        },
        cost_company: {
          required,
        },
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //this.data = [{...tempDevelopmentAction}]
      const non_evaluated_actions = _.filter(this.evaluateActions, function(object){
            return object.new === true;
          });
      this.data.push(...non_evaluated_actions)

    },
    addAction() {
      this.data.push({
        ...tempDevelopmentAction,
      })
    },
    removeElement(index){
      // remove element this.data[index] from this.evaluateActions 
      this.evaluateActions = _.filter(this.evaluateActions, function(object){
            return object.title !== this.data[index].title;
          }.bind(this));

      this.data.splice(index, 1);

    }
  },
  watch: {
    data: {
      handler: function() {
      //   if(this.isAssesment){
      //   this.$emit('error', {
      //     key: 'developmentactions',
      //     value: n.length === 0 ? true : this.$v.$invalid,
      //     error: 'Bitte definieren Sie die Entwicklungsmassnahmen',
      //   })
      // }else{
      //   this.$emit('error', {
      //     key: 'developmentactions',
      //     value: this.$v.$invalid,
      //     error: 'Bitte definieren Sie die Entwicklungsmassnahmen',
      //   })
      // }
        
        this.$emit('error', {
          key: 'developmentactions',
          value: this.$v.$invalid,
          error: 'Bitte definieren Sie die Entwicklungsmassnahmen',
        })

        // remove dublicated object from this.data and this.evaluteActions and merge them
        const mergedArray = _.uniqBy(this.data.concat(this.evaluateActions), 'title');

        //this.$emit('update', { key: 'developmentactions', value: this.evaluateActions })
        this.$emit('update', { key: 'developmentactions', value: mergedArray})
      },
      deep: true,
    },
  },
}
</script>

<style>
.card-header {
  background: none;
  background-color: #e9e9e9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.card {
  box-shadow: 0 1px 1px 1px rgb(33 36 55 / 3%);
  border-radius: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 5px solid #f0f0f0;
}
</style>
